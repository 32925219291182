import { defineStore } from 'pinia';
import { CURRENCIES, countriesCurrencyMap } from '~/utils/enums.ts';
import { useCountryCode } from './country';
import { isWholeNumber } from '~/utils/helpers.ts';
const CURRENCY = CURRENCIES;
export type CURRENCY = keyof typeof CURRENCY;
import { storeToRefs } from 'pinia';
import { useGlobalStore } from './global';

export const useCurrency = defineStore('currency', () => {
	const CountryCodeStore = useCountryCode();
	const { storeType, $conversionRates } = useGlobalStore();
	const { countryCode } = storeToRefs(CountryCodeStore);
	const currencyCookie = useCookie('currency', {
		maxAge: 86400, //cookie max age set to one day
		sameSite: 'strict',
	});

	const checkoutLocalization = ref<boolean>(true);

	const nativeCurrency =
		countriesCurrencyMap[countryCode.value ?? 'PK'] ?? 'usd';

	const selectedCurrency = ref('pkr');

	const persist = () => {
		currencyCookie.value = selectedCurrency.value;
	};

	const hydrate = () => {
		const currency = currencyCookie.value as CURRENCY;
		if (currency) {
			if (CURRENCY[currency]) {
				selectedCurrency.value = currency;
			} else {
				selectedCurrency.value = checkoutLocalization.value ? 'usd' : 'pkr';
				persist();
			}
		} else {
			selectedCurrency.value = nativeCurrency;
			persist();
		}
	};

	onServerPrefetch(() => {
		hydrate();
	});

	const getFormattedPrice = computed(() => {
		return (price: number, noRound: boolean = false) => {
			if (checkoutLocalization.value) {
				try {
					const rate =
						$conversionRates[selectedCurrency.value]?.exchange_rate_to_pk;

					//Since conversion rate is selected currency to PKR, divide by 1 to get the rate
					const convertedPrice = price * (1 / rate!);

					if (selectedCurrency.value === 'pkr') {
						//In case of pkr we have only instance. Always show rounded up value.
						return Math.ceil(convertedPrice).toLocaleString('en-US');
					} else if (selectedCurrency.value === 'usd') {
						//In case of pkr we have three instances. If no round is true or sale price is true than
						// show upto two decimal places otherwise round up to .99
						if (isWholeNumber(convertedPrice)) {
							return `${convertedPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
						} else if (noRound || storeType === 'OCTANE') {
							return convertedPrice.toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							});
						} else {
							return `${Math.floor(convertedPrice).toLocaleString('en-US')}.99`;
						}
					} else {
						//In case of any other currency other than usd and pkr we have two instances. If no round is true or sale price is true than
						// show upto two decimal places otherwise round up to .00
						if (noRound || storeType === 'OCTANE') {
							return convertedPrice.toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							});
						} else {
							return Math.ceil(convertedPrice).toLocaleString('en-US', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							});
						}
					}
				} catch (error) {
					throw new Error('Failed to convert currency');
				}
			} else {
				const rate = $conversionRates[selectedCurrency.value]!;
				const convertedPrice = price * (rate as unknown as number);
				if (selectedCurrency.value === 'pkr') {
					return `${Math.ceil(convertedPrice).toLocaleString('en-US')}`;
				}
				return `${convertedPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
			}
		};
	});

	const setCurrency = (currency: keyof typeof CURRENCY) => {
		if (CURRENCY[currency]) {
			selectedCurrency.value = currency;
			persist();
		}
	};

	const currencySymbol = computed(() => {
		return CURRENCY[selectedCurrency.value as CURRENCY]!.symbol || 'PKR';
	});

	const currencyCode = computed(() => {
		return CURRENCY[selectedCurrency.value as CURRENCY]!.code || 'pkr';
	});

	return {
		getFormattedPrice,
		currencySymbol,
		currencyCode,
		setCurrency,
		selectedCurrency,
	};
});

export const useCurrencyTest = defineStore('currency', () => {
	const selectedCurrency = ref('pkr');
	const currencyCode = ref('pkr');
	const getFormattedPrice = computed(() => {
		return (price: number) => {
			if (selectedCurrency.value === 'pkr') {
				return `${Math.round(price).toLocaleString()}`;
			}
			return `${price.toFixed(2).toLocaleString()}`;
		};
	});

	const setCurrency = (currency: keyof typeof CURRENCY) => {
		if (CURRENCY[currency]) {
			selectedCurrency.value = currency;
		}
	};

	const currencySymbol = computed(() => {
		return CURRENCY[selectedCurrency.value as CURRENCY]!.symbol || 'PKR';
	});

	return {
		getFormattedPrice,
		currencySymbol,
		setCurrency,
		currencyCode,
	};
});
